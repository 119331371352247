import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { IOrderDetails } from '../../../../lib/lib-shared/models/Order';
import { ITrackingSheet } from '../../../../lib/lib-shared/models/TrackingSheet';
import { IOrderArticleTracking } from '../../../../lib/lib-shared/models/OrderArticleTracking';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'lm-tracking-sheet-list-modal',
  templateUrl: './tracking-sheet-list-modal.component.html',
  styleUrls: ['./tracking-sheet-list-modal.component.scss']
})
export class TrackingSheetListModalComponent implements OnInit {
  @ViewChild('refInput') public refInput: ElementRef;
  public refFormControl = new FormControl(null);

  public order: IOrderDetails = null;
  public firstTookTrackingSheet: ITrackingSheet = null;
  public trackingSheetMap: Map<number, number> = new Map();
  public trackingSheetList: [number, number][] = [];
  public orderArticleTrackingList: IOrderArticleTracking[] = [];
  public tookTrackingSheetMap: Map<number, boolean> = new Map();

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
  ) {
  }

  ngOnInit() {
    this.getRefInputFocus();

    this.refFormControl.valueChanges
      .pipe(debounceTime(200),
        distinctUntilChanged(),
        switchMap(ref => {
          if (ref && ref !== '') {
            return of(ref);
          } else {
            return of(null);
          }
        }),
      )
      .subscribe(async (ref) => {
        if (ref) {
          const orderArticleTracking = this.orderArticleTrackingList.find(oat => oat.ref === this.refFormControl.value);
          if (orderArticleTracking) {
            this.addToTookTrackingSheetMap(orderArticleTracking.trackingSheetId);
            this.reset();
          } else {
            const confirmationModal = this.modalService.open(ConfirmationModalComponent, {
              size: 'xl' as 'lg',
              backdrop: 'static',
              keyboard: false,
              centered: true,
              windowClass: 'custom-ngb-modal-window',
              backdropClass: 'custom-ngb-modal-backdrop'
            });
            confirmationModal.componentInstance.confirmationDesc = `Article n'appartenant pas à cette commande`;
            confirmationModal.componentInstance.validateButtonText = 'Ok';
            await confirmationModal.result;
            this.reset();
          }
        } else {
          this.reset();
        }
      });

    this.trackingSheetList = Array.from(this.trackingSheetMap);
    this.addToTookTrackingSheetMap(this.firstTookTrackingSheet.id);

    for (const ob of this.order.orderBags) {
      for (const oa of ob.orderArticles) {
        for (const oat of oa.orderArticleTrackingList) {
          this.orderArticleTrackingList.push(oat);
        }
      }
    }
  }

  addToTookTrackingSheetMap(trackingSheetId: number) {
    this.tookTrackingSheetMap.set(trackingSheetId, true);
    if (this.trackingSheetList.every(([tsId]) => this.tookTrackingSheetMap.has(tsId))) {
      this.activeModal.close();
    }
  }

  reset() {
    this.getRefInputFocus();
    this.refFormControl.reset();
  }

  getRefInputFocus() {
    setTimeout(() => this.refInput.nativeElement.focus());
  }
}
