import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';

import { ApiLavandierService } from '../../../lib/lib-ngx/web-services/api-lavandier.service';
import { ErrorService } from '../../../lib/lib-ngx/services/error.service';
import { IOrderDetails } from '../../../lib/lib-shared/models/Order';
import { TrackingSheetListModalComponent } from '../modals/tracking-sheet-list-modal/tracking-sheet-list-modal.component';
import { ITrackingSheet } from '../../../lib/lib-shared/models/TrackingSheet';
import { PrintService } from '../../services/print.service';
import { ConfirmationModalComponent } from '../modals/confirmation-modal/confirmation-modal.component';
import { ErrorMessage } from '../../../lib/lib-shared/ErrorMessage';


@Component({
  selector: 'lm-packing',
  templateUrl: './packing.component.html',
  styleUrls: ['./packing.component.scss']
})
export class PackingComponent implements OnInit {
  public tagNumberFormControl = new FormControl();
  @ViewChild('tagNumberInput') tagNumberInput: ElementRef;

  public order: IOrderDetails = null;
  public trackingSheetMap: Map<number, number> = new Map();

  constructor(
    private modalService: NgbModal,
    public apiLavandierService: ApiLavandierService,
    private errorService: ErrorService,
    private printService: PrintService,
  ) {
  }

  ngOnInit() {
    this.reset();

    this.tagNumberFormControl.valueChanges
      .pipe(debounceTime(200),
        distinctUntilChanged(),
        switchMap(ref => {
          this.tagNumberFormControl.disable({emitEvent: false});
          if (ref && ref !== '') {
            return forkJoin([
              of(ref),
              this.apiLavandierService.getOrderOrderArticleTrackingRefRefDetails(ref),
            ]).pipe(
              catchError(() => {
                return of([ref, null]);
              })
            );
          } else {
            return of([ref, null]);
          }
        }),
      )
      .subscribe(([ref, order]) => {
        if (order && !this.errorService.manageError(order)) {
          this.order = order;

          let firstTookTrackingSheet = null;
          let oneTrackingSheetIsNotPTLTook = false;
          for (const orderBag of order.orderBags) {
            for (const orderArticle of orderBag.orderArticles) {
              for (const orderArticleTracking of orderArticle.orderArticleTrackingList) {
                let size = 0;
                if (this.trackingSheetMap.has(orderArticleTracking.trackingSheet.id)) {
                  size = this.trackingSheetMap.get(orderArticleTracking.trackingSheet.id);
                }
                this.trackingSheetMap.set(orderArticleTracking.trackingSheet.id, size + 1);
                if (orderArticleTracking.ref === ref) {
                  firstTookTrackingSheet = orderArticleTracking.trackingSheet;
                }
                if (!orderArticleTracking.trackingSheet.PTLTookByLavandierId) {
                  oneTrackingSheetIsNotPTLTook = true;
                }
              }
            }
          }

          if (oneTrackingSheetIsNotPTLTook) {
            this.errorService.showModal(ErrorMessage.TRACKINGSHEET_NOT_PTLTOOK);
            this.reset();
          } else {
            this.openTrackingSheetListModal(firstTookTrackingSheet);
          }
        } else {
          this.reset();
        }
      });
  }

  reset() {
    this.tagNumberFormControl.enable({emitEvent: false});
    this.getTagNumberInputFocus();
    this.tagNumberFormControl.reset();

    this.trackingSheetMap = new Map();
  }

  getTagNumberInputFocus() {
    setTimeout(() => this.tagNumberInput.nativeElement.focus());
  }

  async openTrackingSheetListModal(firstTookTrackingSheet: ITrackingSheet) {
    if (this.trackingSheetMap.size === 1 && this.trackingSheetMap.has(firstTookTrackingSheet.id)) {
      this.managePrintModals();
    } else {
      const trackingSheetListModalComponent = this.modalService.open(TrackingSheetListModalComponent, {
        size: 'xl' as 'lg',
        backdrop: 'static',
        keyboard: false,
        centered: true
      });
      trackingSheetListModalComponent.componentInstance.order = this.order;
      trackingSheetListModalComponent.componentInstance.firstTookTrackingSheet = firstTookTrackingSheet;
      trackingSheetListModalComponent.componentInstance.trackingSheetMap = this.trackingSheetMap;
      await trackingSheetListModalComponent.result;
      this.managePrintModals();
    }
  }

  managePrintModals() {
    this.apiLavandierService.putOrderIdTransportSheet(this.order.id)
      .subscribe(async (data: any) => {
        if (!this.errorService.manageError(data)) {
          for (const transportSheetDataOrderKit of data.transportSheetDataOrderKitList) {
            for (const transportSheetData of transportSheetDataOrderKit.transportSheetDataList) {
              await this.printService.print(transportSheetData.pdfBase64).toPromise();
            }
            await this.openPrintModal(`${transportSheetDataOrderKit.transportSheetDataList.length} x sac ${transportSheetDataOrderKit.orderKit.name}`);
          }

          await this.printService.print(data.transportSheetData.pdfBase64).toPromise();
          await this.openPrintModal('Etiquette transport');
          this.reset();
        }
        this.reset();
      });
  }

  async openPrintModal(modalText: string) {
    const confirmationModal = this.modalService.open(ConfirmationModalComponent, {
      size: 'xl' as 'lg',
      backdrop: 'static',
      keyboard: false,
      centered: true
    });
    confirmationModal.componentInstance.confirmationDesc = modalText;
    confirmationModal.componentInstance.validateButtonText = 'Ok';
    await confirmationModal.result;
  }
}
