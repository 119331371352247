import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { DateFormatPipe, MomentModule } from 'ngx-moment';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ZXingScannerModule } from '@zxing/ngx-scanner';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { LoginComponent } from './components/login/login.component';
import { MenuComponent } from './components/menu/menu.component';
import { DebugComponent } from './components/debug/debug.component';
import { ScanTagComponent } from './components/scan-tag/scan-tag.component';
import { StorageListComponent } from './components/storage-list/storage-list.component';
import { DeliveryOrderComponent } from './components/delivery-order/delivery-order.component';

import { EnumToArrayPipe } from '../lib/lib-ngx/pipes/enum-to-array.pipe';
import { FreeStorageModalComponent } from './components/modals/free-storage-modal/free-storage-modal.component';
import { ErrorModalComponent } from './components/modals/error-modal/error-modal.component';
import { SearchComponent } from './components/search/search.component';
import { ScanStorageModalComponent } from './components/modals/scan-storage-modal/scan-storage-modal.component';
import { HttpErrorInterceptorService } from '../lib/lib-ngx/services/http-error-interceptor.service';
import { ConfigService } from '../lib/lib-ngx/services/config.service';
import { ControlComponent } from './components/control/control.component';
import { ScanTrackingSheetComponent } from './components/control/scan-tracking-sheet/scan-tracking-sheet.component';
import { ConfirmationModalComponent } from './components/modals/confirmation-modal/confirmation-modal.component';
import { HasFlagsPipe } from '../lib/lib-ngx/pipes/has-flags.pipe';
import { APP_TOKEN, appInitializerFn } from '../lib/lib-ngx/services/AppInitializerFn';
import { VersionService } from '../lib/lib-ngx/services/version.service';
import { HttpInterceptorVersionService } from '../lib/lib-ngx/services/http-interceptor-version.service';
import { HomeComponent } from './components/home/home.component';
import { CompleteOrdersComponent } from './components/complete-orders/complete-orders.component';
import { PackingComponent } from './components/packing/packing.component';
import { TrackingSheetListModalComponent } from './components/modals/tracking-sheet-list-modal/tracking-sheet-list-modal.component';
import { PrepareKitBatchComponent } from './components/prepare-kit-batch/prepare-kit-batch.component';
import { OrderKitBatchScanModalComponent } from './components/modals/order-kit-batch-scan-modal/order-kit-batch-scan-modal.component';

declare function require(moduleName: string): any;

// todo change when upgrading angular version https://stackoverflow.com/a/48869478
export const npm = require('../../package.json');

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MenuComponent,
    DebugComponent,
    ScanTagComponent,
    StorageListComponent,
    DeliveryOrderComponent,
    EnumToArrayPipe,
    FreeStorageModalComponent,
    ErrorModalComponent,
    SearchComponent,
    ScanStorageModalComponent,
    ControlComponent,
    ScanTrackingSheetComponent,
    ConfirmationModalComponent,
    HasFlagsPipe,
    HomeComponent,
    CompleteOrdersComponent,
    PackingComponent,
    TrackingSheetListModalComponent,
    PrepareKitBatchComponent,
    OrderKitBatchScanModalComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    NgHttpLoaderModule,
    NgbModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    MomentModule,
    ZXingScannerModule,
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptorService, multi: true},
    DateFormatPipe,
    {provide: APP_TOKEN, useValue: {name: npm.name, version: npm.version}},
    {provide: APP_INITIALIZER, useFactory: appInitializerFn, multi: true, deps: [ConfigService, VersionService, APP_TOKEN]},
    {provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorVersionService, multi: true},
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    FreeStorageModalComponent,
    ErrorModalComponent,
    ScanStorageModalComponent,
    ConfirmationModalComponent,
    TrackingSheetListModalComponent,
    OrderKitBatchScanModalComponent,
  ]
})
export class AppModule {
}
