import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { IOrderDetailsLIstOfKitBatchWaitingShipping } from '../../../../lib/lib-shared/models/Order';
import { PRICEMULTIPLIER } from '../../../../lib/lib-shared/defines';

@Component({
  selector: 'lm-order-kit-batch-scan-modal',
  templateUrl: './order-kit-batch-scan-modal.component.html',
  styleUrls: ['./order-kit-batch-scan-modal.component.scss']
})
export class OrderKitBatchScanModalComponent implements OnInit {
  public PRICEMULTIPLIER = PRICEMULTIPLIER;

  @ViewChild('orderIdInput') public orderIdInput: ElementRef;
  public orderIdFormControl = new FormControl(null);

  public order: IOrderDetailsLIstOfKitBatchWaitingShipping = null;

  constructor(
    public activeModal: NgbActiveModal,
  ) {
  }

  ngOnInit() {
    this.getOrderIdInputFocus();

    this.orderIdFormControl.valueChanges
      .pipe(debounceTime(200),
        distinctUntilChanged(),
        switchMap(ref => {
          if (ref && ref !== '') {
            return of(ref);
          } else {
            return of(null);
          }
        }),
      )
      .subscribe(ref => {
        if (ref) {
          this.activeModal.close(ref);
        }
      });
  }

  getOrderIdInputFocus() {
    setTimeout(() => this.orderIdInput.nativeElement.focus());
  }
}
