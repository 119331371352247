import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { IUser } from '../../../../lib/lib-shared/models/User';
import { IUserType } from '../../../../lib/lib-shared/models/UserType';
import { IOrder } from '../../../../lib/lib-shared/models/Order';

@Component({
  selector: 'lm-scan-storage-modal',
  templateUrl: './scan-storage-modal.component.html',
  styleUrls: ['./scan-storage-modal.component.scss']
})
export class ScanStorageModalComponent implements OnInit {
  @ViewChild('storageNumberInput') public storageNumberInput: ElementRef;
  public storageRefFormControl = new FormControl(null);

  public ref = null;
  public size = null;
  public userType: IUserType = null;
  public user: IUser = null;
  public order: IOrder = null;
  public name: string = null;

  constructor(
    public activeModal: NgbActiveModal,
  ) {
  }

  ngOnInit() {
    this.getStorageNumberInputFocus();

    this.storageRefFormControl.valueChanges
      .pipe(debounceTime(200),
        distinctUntilChanged(),
        switchMap(ref => {
          if (ref && ref !== '') {
            return of(ref);
          } else {
            return of(null);
          }
        }),
      )
      .subscribe(ref => {
        if (ref) {
          if (ref === this.ref) {
            this.activeModal.close(false);
          } else if (ref === this.ref + '-FULL') {
            this.activeModal.close(true);
          }
        }
      });
  }

  getStorageNumberInputFocus() {
    setTimeout(() => this.storageNumberInput.nativeElement.focus());
  }
}
